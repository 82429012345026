<template>
    <div ref="plyr" :style="style">
        <iframe
            :src="src" ></iframe>
    </div>
</template>
<script>

import Plyr from 'plyr';
import 'plyr/dist/plyr.css'
export default {
    props: {
        src: {
            type: String
        },
        poster: {
            type: String
        },
        width: {
            type: String
        }, 
        'min-width': {
            type: String
        }, 
        'max-width': {
            type: String
        }, 
        height: {
            type: String
        }, 
        'min-height': {
            type: String
        }, 
        'max-height' : {
            type: String
        }
    },
    computed: {
        style() {
            return {
                width: this.width, 
                'min-width': this['min-width'], 
                'max-width': this['max-width'], 
                height: this['height'], 
                'min-height': this['min-height'], 
                'max-height' : this['max-height']
            }
        }
    },
    mounted() {
        const config = {};
        if (this.poster) {
            config.poster = this.poster;
        }
        this.player = new Plyr(this.$refs.plyr, config);
    },
    beforeDestroy() {
        try {
            this.player.stop();
            this.player.destroy();
        } catch (_){
            return;
        }
        
    }
}
</script>